<template>
    <div style="padding-bottom:60px">

        <div class="info">
            <div class="info-img" :style="{backgroundImage: 'url(' + require('../../assets/img/' + infoPic) + ')'}"></div>
            <ul>
                <li>SIM卡号：{{arrs.msisdn}}</li>
                <li>ICCID：{{arrs.iccid}}</li>
                <li>套餐名称：{{arrs.packName}}</li>
            </ul>
        </div>
        <div class="orderForm">
            <van-cell-group>
                <van-cell title="套餐详情" icon="description" value=""/>
                <van-cell title="卡片状态">
                    <van-tag slot="default" size="medium" plain :type="cardStatus.type">{{cardStatus.label}}</van-tag>
                </van-cell>
                <!-- <van-cell title="单价" :value="`${arrs.price}`"/> -->
                <van-cell title="可用周期" :value="`${arrs.period}`"/>
                <van-cell title="剩余可使用流量" :value="`${arrs.periodCanUsage - arrs.usageAmount}M`"/>
                <van-cell title="套餐开始时间" :value="`${arrs.periodStartTime}`"/>
                <van-cell title="套餐结束时间" :value="`${arrs.periodEndTime}`"/>
                <van-cell title="卡片到期时间" :value="`${arrs.cardEndTime}`"/>
                <!-- <van-field name="stepper" label="周期">
                    <template #input>
                         
                        <van-stepper @change="onChangeStepper" :v-model="period" :min="form.periods" :max="99" integer/>
                    </template>
                </van-field> -->
                <van-field label="周期"
                    type="number"
                    disabled
                    v-model.number="form.period"
                    placeholder="请输入整数"
                    @input="onChangeStepper"
                />
            </van-cell-group>
            <div class="mySwipe">
                <div class="swipeTitle">升级套餐次月生效，生效后流量和时间将重新计算。</div>
                <van-swipe :loop="false" :width="200" :show-indicators=false @change="onChange">
                    <van-swipe-item v-for="(it,i) of pack" :key="i">
                        <div class="swipeDiv" :class="{active:current == i}">
                            <div>{{it.name}}</div>
                            <div style="margin:6px 0">套餐可使用周期：{{it.period}}</div>
                            <div>单价：￥{{it.price}}元</div>
                        </div>
                    </van-swipe-item>
                 
                </van-swipe>
                <div class="swipeNote">
                    <span>*应付金额=套餐单价 × 周期</span><br>
                    <span>*需付金额=新套餐单价 × 周期-｛旧套餐单价 ×(旧套餐周期数-1)}</span>       
                </div>
            </div>
            <van-submit-bar v-if="flag"
                :loading="loading"
                :price="price * 100"
                button-text="提交订单"
                @submit="onSubmit">
            </van-submit-bar>

            <van-submit-bar v-if="!flag"
                disabled
                tip="暂无升级套餐，请联系上级添加"
                tip-icon="info-o"
                price="0"
                button-text="提交订单"
                @submit="onSubmit">
            </van-submit-bar>
        </div>
        
    </div>
</template>
<script>
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    name:'upPack',
    data() {
        return {
            flag:true,
            loading:false,
            infoPic: 'cmcc.png',
            arrs:{},
            form:{},
            pack:[],
            cardStatus:{
                type:'success',
                label:'正常'
            },
            current:0,
            price:0,
            period:12,
            chargeOrderId:'',
            timer:Object,
            order:{},
        }
    },
    computed:{
        // price(){
        //     return this.form.period * this.form.price
        // },
    },
    methods: {
        payFn(d){
            if (!this.$store.state.wxSDKReady) {
                setTimeout(this.payFn, 1000);
                return;
            }
            this.checkHasPay();
            wx.chooseWXPay({
                timestamp: d.data.timestamp,
                nonceStr:  d.data.nonceStr,
                package:   d.data.packAge,
                signType: "MD5",
                paySign:   d.data.sign,
                success: res => {

                }
            });
        },
        createAccChargeOrder(){
            let params = {
                amount:this.price,
                // orderForm:1,
                // amount:0.01,
                payWay:'weixin-official'};
            this.$api.createAccChargeOrder(params).then(res => {
                if(res.data.code ==0){
                    this.chargeOrderId = res.data.data.orderId
                    this.createAccChargeOrderPay();
                }
            })
        },
        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay2(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay2(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        checkHasPay(){
            let check = async () =>{
                let params = {chargeOrderId:this.chargeOrderId};
                Toast.loading({duration: 0,mask: true, forbidClick: true });
                let res = await this.$api.queryHasPayed(params);
                //已经支付
                if(!!res.data.data){
                    Toast.clear();
                    if(localStorage.groupClientFlag == '1'){
                        Toast.success('充值成功');
                        this.$router.push({name:'chargeUp'}); 
                    }else{
                        this.createRefueingOrder();
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await check();
                }
            }
            check();
        },
        createRefueingOrder(){
            // let params = this.order;
            // params.msisdns = [this.arrs.msisdn];
            let params = Object.assign({...this.order,chargeOrderId:this.chargeOrderId},{openId:localStorage.openId});
            this.$api.addToUpPack(params).then(res => {
                if(res.data.code == 0){
                    if(localStorage.groupClientFlag == '1'){
                        this.chargeOrderId = res.data.data
                        this.createAccChargeOrderPay2()
                    }else{
                        Toast.success('充值成功');
                        this.$router.push({name:'chargeUp'});
                    }
                    // localStorage.upOrder = res.data.data
                    // localStorage.removeItem('uppack');
                    // Toast.success('充值成功');
                    // this.$router.push({name:'chargeUp'});
                }
            })
        },
        onSubmit(){
            let index = this.period
            if(!index || String(index).indexOf('.') != -1){
                this.$toast.fail("请输入整数")
                return;
            }
            if(this.form.period < this.form.minPeriod){
                this.$toast.fail(`不能小于最小周期数${this.form.minPeriod}`);
                return;
            }
            this.order = {
                msisdn:this.arrs.msisdn,
                iccid:this.arrs.iccid,
                packName:this.pack[this.current].name,
                period:this.form.period,
                packId:this.pack[this.current].id,
                firstPrice:this.form.price,
                date:this.$dateAdd(1, new Date()),
                price:this.price,
                orderFrom:1,
                msisdns: [this.arrs.msisdn]
            }
            localStorage.uppack = JSON.stringify(this.order)
            this.$dialog.confirm({
                title: '升级套餐',
                message: '升级套餐次月才能生效，您确定升级套 餐吗？生效后，套餐时间和流量将重新计算。'
            }).then(() => {
                // this.$router.push({name:'payRefueing'});
                this.$api.upOrderEstablish(this.order).then(res => {
                    if(res.data.code == 0){
                        if(localStorage.groupClientFlag == '1'){
                            this.createRefueingOrder()
                        }else{
                            this.createAccChargeOrder();
                        }
                    }
                    // if(res.data.code == 0)this.createAccChargeOrder();
                })
            }).catch(() => {
                // on cancel
            });
        },
        onChangeStepper(index){
            // if(this.period == index) return;
            this.period = index
            if(!index || String(index).indexOf('.') != -1){
                this.$toast.fail("请输入整数")
                return;
            }
            this.loading = true
            this.$api.getAmountUpPack({msisdns:this.arrs.msisdn,packId:this.pack[this.current].id,
            period:this.form.period}).then(res => {
                if(res.data.code == 0){
                    this.loading = false
                    this.price = res.data.data.actualAmount
                }
                
            })
        },
        onChange(index) {
            // this.loading = true
            this.current = index;
            this.form = {
                price:this.pack[index].price,
                period:this.pack[index].period,
                minPeriod:this.pack[index].period,
            }
            this.onChangeStepper(this.form.period)
        }
    },
    created() {
        this.$store.commit('setPageTitle', "升级套餐");
        if(!localStorage.message){
            this.$api.getCardInfo({cardNumber:localStorage.msisdn}).then(res => {
                if(res.data.code == 0){
                    this.arrs = Object.assign({},res.data.data)
                    localStorage.message = JSON.stringify(this.arrs)
                    this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
                }
            })
        }else{
            this.arrs = JSON.parse(localStorage.message)
            this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
        }
        this.$api.getToUpPack({msisdn:localStorage.msisdn}).then(res => {
            if(res.data.code == 0){
                if(res.data.data.length == 0){
                    this.flag = false;
                }else{
                    this.pack = res.data.data
                    this.onChange(0)
                }
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.info{
    width: 750px;
    height: 255px;
    background-color: #209df2;
    &-img{
        float: left;
        width: 195px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 134px;
    }
    ul{
        float: left;
        margin: 38px 0 0 18px;
        width: 520px;
        li{
            color: #fff;
            font-size: 26px;
            text-align: left;
            height: 61.5px;
            line-height: 61.5px;
        }
    }
}
.orderForm{
    text-align: left;
    .van-submit-bar{
        border-top:1px solid #ddd;
    }
}

.mySwipe{
    width: 90%;margin: 0 auto;font-size: 24px;font-weight: 600;
    .swipeTitle{
        color: #EB5E00;margin: 20px 0px 0 24px ;
        font-size: 22px;
    }
    .swipeDiv{
        text-align: left;padding: 20px 30px;
        border:1px solid #ddd;
        margin: 5%;
        
        
    }
    .swipeNote{
        color: #999999;font-size: 20px;
        padding-bottom: 60px;
        span{
            margin-left: 16px;
        }
    }
    .active{
        color: #EB5E00;
        border:1px solid sandybrown;
    }
}

</style>